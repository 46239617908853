import "./front.scss";

const toast = {
  buildTemplate: function (txt) {
    return `<div class="toast-body">${txt.replace("<", "&lt;").replace(">", "&gt;").replace(/\*\*(.*?)\*\*/gm, '<strong>$1</strong>').replace(/\\n/g, '<br>')}</div>`;
  },

  display: function (txt, conf ) {
    const defaultConfig = { delay: 1500, container: '.ljd_notification_container'};
    const config = Object.assign(defaultConfig, conf);
    const container = document.querySelector(config.container);
    if (!container) {
      console.error(
        `ljd_notification: toast container not found`
      );
      return;
    }
    const toast = document.createElement("div");
    toast.classList.add("toast", "ljd_toast", "animate");
    toast.innerHTML = this.buildTemplate(txt);
    toast.setAttribute("data-delay", config.delay);
    container.prepend(toast);
    const $toast = $(toast);
    $toast.on('hidden.bs.toast', function () {
      $toast.remove();
    });
    $toast.toast("show");
  },
};

export { toast };